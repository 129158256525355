<template>
    <div class="device-settings-layout">
        <div class="dsl-header">{{$t('settings.deviceSettings')}}</div>
        <div class="dsl-tabs">
            <slot name="tabs"/>
        </div>
        <div class="dsl-row">
            <div class="dsl-left">
                <block-box class="dsl-content-box" :header="$t('settings.aISettings')">
                    <slot name="left"/>
                </block-box>
            </div>
            <div class="dsl-right" v-show="showFirmwareUpgrade">
                <block-box class="dsl-content-box" :header="$t('settings.firmwareUpgrade')">
                    <slot name="right"/>
                </block-box>
            </div>
        </div>
        <div class="dsl-action-btn">
            <slot name="button"/>
        </div>
    </div>
</template>

<script>
    import BlockBox from "./BlockBox";

    export default {
        name: "DeviceSettingdslayout",
        props: {
            showFirmwareUpgrade: {
                type: Boolean,
                default: false
            }
        },
        components: {
            BlockBox,
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $border-color=$color_gray_third

    .dsl-header
        font-size 16px
        line-height 16px
        font-weight 600
        padding-bottom 15px
        padding-top 20px
        border-top 1px solid $border-color

    .dsl-tabs
        padding-bottom 15px

    .dsl-row
        display flex
        justify-content space-between
        padding-bottom 20px

        .dsl-left, .dsl-right
            height 100%
            width calc(50% - 10px)

            .dsl-content-box
                height 100%

    .dsl-action-btn
        padding-top 20px
        padding-left 25px
</style>