<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="page-settings">
        <global-settings-layout>
            <template v-slot:unitSettings>
                <div class="ps-unit-settings">
                    <div class="ps-global-setting-row">
                        <div class="ps-left">
                            <setting-lock v-model="isLocked_test"/>
                            {{$t('settings.unitOfMeasure')}}
                        </div>
                        <div class="ps-right">
                            <el-select class="ps-select-width" v-model="unit" size="small">
                                <el-option v-for="item in unitOptions" :key="item.value"
                                           :label="item.label" :value="item.value"/>
                            </el-select>
                        </div>
                    </div>
                    <div class="ps-global-setting-row">
                        <div class="ps-left">
                            <setting-lock v-model="isLocked_test"/>
                            {{$t('settings.timeFormat')}}
                        </div>
                        <div class="ps-right">
                            <el-select class="ps-select-width" v-model="dateFormat" size="small">
                                <el-option v-for="item in timeFormatOptions"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"/>
                            </el-select>
                        </div>
                    </div>

                    <div class="ps-global-setting-row">
                        <div class="ps-left">
                            <setting-lock v-model="isLocked_test"/>
                            {{$t('login.portalLanguage')}}
                        </div>
                        <div class="ps-right">
                            <el-select class="ps-select-width" v-model="lang" size="small"
                                       @change="switchLanguage(lang)">
                                <el-option
                                        v-for="item in language"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:alertSettings>
                <div class="ps-alert-settings">
                    <div class="ps-global-setting-row">
                        <div class="ps-left">
                            <setting-lock v-model="isLocked_test"/>
                            {{$t('settings.alertRecordingDuration')}}
                        </div>
                        <div class="ps-right">
                            <el-select class="ps-select-width" v-model="AlertRecordTime" size="small">
                                <el-option
                                        v-for="item in alertRecordingOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            {{ $t('settings.secs')}}
                        </div>
                    </div>
                    <div class="ps-global-setting-row">
                        <div class="ps-left">
                            <setting-lock v-model="isLocked_test"/>
                            {{$t('settings.tripAudioRecording')}}
                        </div>
                        <div class="ps-right">
                            <el-switch v-model="muteRecord" :active-value="0" :inactive-value="1"
                                       active-color="#6995D6" inactive-color="#909090"/>
                        </div>
                    </div>

                    <div class="ps-global-setting-row" style="margin-bottom: 5px">
                        <div class="ps-left">
                            <setting-lock v-model="isLocked_test"/>
                            {{$t('settings.way2CallRecording')}}
                        </div>
                        <div class="ps-right">
                            <el-switch v-model="callRecord" :active-value="1" :inactive-value="0"
                                       active-color="#6995D6" inactive-color="#909090"/>
                        </div>
                    </div>

                </div>
            </template>
            <template v-slot:driverLoginSettings>
                <div class="ps-driver-login-settings">
                    <div class="ps-global-setting-row">
                        <div class="ps-left">
                            <setting-lock v-model="isLocked_test"/>
                            {{$t('settings.loginMethod')}}
                        </div>
                        <div class="ps-right">
                            <el-radio v-model="loginMethod" :label="0">{{$t('settings.QRCode')}}</el-radio>
                            <el-radio v-model="loginMethod" :label="1">{{$t('settings.QRCodeOrFaceID')}}</el-radio>
                        </div>
                    </div>
                    <div class="ps-global-setting-row" style="padding-bottom: 0">
                        <div class="ps-left">
                            <setting-lock v-model="isLocked_test"/>
                            {{$t('settings.loginScanTime')}}
                        </div>
                        <div class="ps-right">
                            <el-input-number class="ps-box ps-input-width" size="small" v-model="scanTTL"
                                             :min="5" :max="300" controls-position="right"
                                             @focus="onInputFocus" @blur="onInputBlur"/>
                            {{secs}}
                            <div style="height: 20px;margin-top: -5px;color: #6995D6;font-size: 12px;">
                                {{loginScanTimeTip}}
                            </div>
                        </div>
                    </div>
                    <div class="ps-global-setting-row" style="padding-bottom: 0">
                        <div class="ps-left">
                            <setting-lock v-model="isLocked_test"/>
                            {{$t('settings.loginPictureDelay')}}
                        </div>
                        <div class="ps-right">
                            <el-input-number class="ps-box ps-input-width" size="small" v-model="takePictureTime"
                                             controls-position="right" :min="20" :max="300"
                                             @focus="onLoginPictureInputFocus" @blur="onLoginPictureInputBlur"/>
                            {{secs}}
                            <div style="height: 20px;margin-top: -5px;color: #6995D6;font-size: 12px">
                                {{loginPictureDelayTip}}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:audioSettings>
                <div class="ps-audio-settings">

                    <div class="ps-global-setting-row">
                        <div class="ps-left">
                            <setting-lock v-model="isLocked_test"/>
                            {{$t('settings.parkingAndCollisionSensitivity')}}
                        </div>
                        <div class="ps-right">
                            <el-slider class="ps-box ps-min-max  ps-slider ps-slider-width" size="mini"
                                       v-model="GsensorEventSensitivityProxy"
                                       :marks="sensitivityMarks"
                                       @change="changeSensitivity"
                                       :min="5" :max="20" :step="1" :show-tooltip="false"/>
                        </div>
                    </div>

                    <div class="ps-global-setting-row">
                        <div class="ps-left">
                            <setting-lock v-model="isLocked_test"/>
                            {{$t('login.alertLanguage')}}
                        </div>
                        <div class="ps-right">
                            <el-select class="ps-select-width" v-model="AlertLanguage" size="small">
                                <el-option
                                        v-for="item in alertLanguage"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>


                </div>
            </template>
        </global-settings-layout>

        <device-settings-layout v-show="showDeviceSettings" :show-firmware-upgrade="isShowFirmwareUpgrade">
            <template v-slot:tabs>
                <div class="ps-tabs-box">
                    <div v-if="deviceSettings.D700.show" class="ps-tabs"
                         @click="switchActiveSetting('D700')"
                         :class="{'ps-tab-active': deviceSettings.activeSetting === 'D700'}">
                        <span v-if="D700HasNewVersion" class="ps-icon via-upgrade"/>Mobile360 D700
                    </div>
                    <div v-if="deviceSettings.D700S.show" class="ps-tabs"
                         @click="switchActiveSetting('D700S')"
                         :class="{'ps-tab-active': deviceSettings.activeSetting === 'D700S'}">
                        <span v-if="D700SHasNewVersion" class="ps-icon via-upgrade"/>Mobile360 D700S
                    </div>
                    <div v-if="deviceSettings.M800.show" class="ps-tabs"
                         @click="switchActiveSetting('M800')"
                         :class="{'ps-tab-active': deviceSettings.activeSetting === 'M800'}">
                        <span v-if="M810HasNewVersion" class="ps-icon via-upgrade"/>Mobile360 M800
                    </div>

                    <div v-if="deviceSettings.M810.show" class="ps-tabs"
                         @click="switchActiveSetting('M810')"
                         :class="{'ps-tab-active': deviceSettings.activeSetting === 'M810'}">
                        <span v-if="M810HasNewVersion" class="ps-icon via-upgrade"/>Mobile360 M810
                    </div>
                    <div v-if="deviceSettings.M820.show" class="ps-tabs"
                         @click="switchActiveSetting('M820')"
                         :class="{'ps-tab-active': deviceSettings.activeSetting === 'M820'}">
                        <span v-if="M820HasNewVersion" class="ps-icon via-upgrade"/>Mobile360 M820
                    </div>
                </div>
            </template>
            <template v-slot:left>
                <div v-show="deviceSettings.activeSetting === 'D700'"
                     class="ps-mobile360-d700 ps-mobile360-d700-height slide_top">
                    <div class="ps-ai-left-box">
                        <div class="ps-top">
                            <div class="ps-ldw">
                                <div class="ps-ldw-row">
                                    <div class="ps-name">
                                        <setting-lock v-model="isLocked_test"/>
                                        {{$t('settings.LDW')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.D700.data.LDW"
                                                   :active-value="1" :inactive-value="0"
                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>
                                <div class="ps-ldw-row padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option"
                                           :class="{'checkbox-grey': deviceSettings.D700.data.LDW !== 1}">
                                        <span class="ps-ldw-audio-alert">{{$t('settings.LDWAudioAlert')}}</span>
                                        <el-checkbox v-model="deviceSettings.D700.data.LDWAudioAlert"
                                                     :disabled="deviceSettings.D700.data.LDW !== 1"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>


                            </div>
                        </div>
                        <div class="ps-bottom">
                            <div class="ps-fcw-row item-padding">
                                <div class="ps-name">
                                    <setting-lock v-model="isLocked_test"/>
                                    {{$t('settings.FCW')}}
                                </div>
                                <div class="ps-switch">
                                    <el-switch v-model="deviceSettings.D700.data.FCW"
                                               :active-value="1" :inactive-value="0"
                                               active-color="#6995D6" inactive-color="#909090"/>
                                </div>
                            </div>
                            <div class="ps-ldw-row padding-left-space item-padding">
                                <setting-lock v-model="isLocked_test"/>
                                <label class="ps-label-option"
                                       :class="{'checkbox-grey': deviceSettings.D700.data.FCW !== 1}">
                                    <span class="ps-ldw-audio-alert">{{$t('settings.FCWAudioAlert')}}</span>
                                    <el-checkbox v-model="deviceSettings.D700.data.FCWAudioAlert"
                                                 :disabled="deviceSettings.D700.data.FCW !== 1"
                                                 :true-label="1" :false-label="0"/>
                                </label>
                            </div>

                            <div class="ps-ldw-row padding-left-space item-padding"
                                 :class="{'checkbox-grey': deviceSettings.D700.data.FCW !== 1}">
                                <setting-lock v-model="isLocked_test"/>
                                {{$t('settings.FCWAlertSensitivity')}}
                                <div class="ps-ldw-slider">
                                    <el-slider class="ps-box  ps-slider ps-slider-width" size="mini"
                                               v-model="D700FCWLevel"
                                               :marks="FCWAlertSensitivityMarks"
                                               :disabled="deviceSettings.D700.data.FCW !== 1"
                                               :min="0" :max="2" :step="1" :show-tooltip="false"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ps-ai-right-box">
                        <div class="ps-top">
                            <div class="ps-fcw ps-fcw-padding">
                                <div class="ps-dms-row">
                                    <div class="ps-name">
                                        <setting-lock v-model="isLocked_test"/>
                                        {{$t('settings.DMS')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.D700.data.DMS"
                                                   @change="onD700DMSSwitchChanged"
                                                   :active-value="1" :inactive-value="0"
                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>


                                <div class="ps-dms-option-row-level3 padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <div class="ps-name-level2">
                                        {{$t('settings.distractedDrivingAlert')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.D700.data.DMSDistractedDisabled"
                                                   :active-value="0" :inactive-value="1"
                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option"
                                           :class="{'checkbox-grey': deviceSettings.D700.data.DMS !== 1}">
                                        <div class="ps-dms-option">{{$t('settings.distractedDrivingAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.D700.data.DMSDistractedAudioAlert"
                                                     :disabled="deviceSettings.D700.data.DMS !== 1 || deviceSettings.D700.data.DMSDistractedDisabled !== 0"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>


                                <div class="ps-dms-option-row-level3 padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <div class="ps-name-level2">
                                        {{$t('settings.driverFatigueAlert')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.D700.data.DMSFatigueDisabled"
                                                   :active-value="0" :inactive-value="1"
                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>

                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option"
                                           :class="{'checkbox-grey': deviceSettings.D700.data.DMS !== 1}">
                                        <div class="ps-dms-option">{{$t('settings.driverFatigueAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.D700.data.DMSFatigueAudioAlert"
                                                     :disabled="deviceSettings.D700.data.DMS !== 1 || deviceSettings.D700.data.DMSFatigueDisabled !== 0"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>


                                <div class="ps-dms-option-row-level3 padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <div class="ps-name-level2">
                                        {{$t('settings.phoneUsageAlert')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.D700.data.DMSPhoneDisabled"
                                                   :active-value="0" :inactive-value="1"

                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option"
                                           :class="{'checkbox-grey': deviceSettings.D700.data.DMS !== 1}">
                                        <div class="ps-dms-option">{{$t('settings.phoneUsageAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.D700.data.DMSPhoneAudioAlert"
                                                     :disabled="deviceSettings.D700.data.DMS !== 1 || deviceSettings.D700.data.DMSPhoneDisabled !== 0"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>


                                <div class="ps-dms-option-row-level3 padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <div class="ps-name-level2">
                                        {{$t('settings.smokingAlert')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.D700.data.DMSSmokingDisabled"
                                                   :active-value="0" :inactive-value="1"

                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option"
                                           :class="{'checkbox-grey': deviceSettings.D700.data.DMS !== 1}">
                                        <div class="ps-dms-option">{{$t('settings.smokingAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.D700.data.DMSSmokingAudioAlert"
                                                     :disabled="deviceSettings.D700.data.DMS !== 1 || deviceSettings.D700.data.DMSSmokingDisabled !== 0"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div v-show="deviceSettings.activeSetting === 'D700S'"
                     class="ps-mobile360-d700 ps-mobile360-m810-height">
                    <div class="ps-ai-left-box" style="border: none">
                        <div class="ps-top">
                            <div class="ps-ldw">
                                <div class="ps-ldw-row padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <span class="ps-ldw-audio-alert">{{$t('settings.LDWAudioAlert')}}</span>
                                        <el-checkbox v-model="deviceSettings.D700S.data.LDWAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>

                                <div class="ps-fcw-row padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <span class="ps-fcw-audio-alert">{{$t('settings.FCWAudioAlert')}}</span>
                                        <el-checkbox v-model="deviceSettings.D700S.data.FCWAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>

                                <div class="ps-fcw-row padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    {{$t('settings.FCWAlertSensitivity')}}
                                    <div class="ps-fcw-slider">
                                        <el-slider class="ps-box  ps-slider ps-slider-width" size="mini"
                                                   v-model="D700SFCWLevel"
                                                   :marks="FCWAlertSensitivityMarks"
                                                   :min="0" :max="2" :step="1" :show-tooltip="false"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="ps-ai-right-box ps-bottom">
                        <div class="ps-top left-border">
                            <div class="ps-fcw ps-fcw-padding">
                                <div class="ps-dms-row">
                                    <div class="ps-name">
                                        <setting-lock v-model="isLocked_test"/>
                                        {{$t('settings.DMS')}}
                                    </div>
                                </div>
                                <div class="ps-dms-option-row-level3 padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <div class="ps-name-level2">
                                        {{$t('settings.distractedDrivingAlert')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.D700S.data.DMSDistractedDisabled"
                                                   :active-value="0" :inactive-value="1"
                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <div class="ps-dms-option">{{$t('settings.distractedDrivingAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.D700S.data.DMSDistractedAudioAlert"
                                                     :disabled="deviceSettings.D700S.data.DMSDistractedDisabled !== 0"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>


                                <div class="ps-dms-option-row-level3 padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <div class="ps-name-level2">
                                        {{$t('settings.driverFatigueAlert')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.D700S.data.DMSFatigueDisabled"
                                                   :active-value="0" :inactive-value="1"
                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>

                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <div class="ps-dms-option">{{$t('settings.driverFatigueAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.D700S.data.DMSFatigueAudioAlert"
                                                     :disabled="deviceSettings.D700S.data.DMSFatigueDisabled !== 0"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>


                                <div class="ps-dms-option-row-level3 padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <div class="ps-name-level2">
                                        {{$t('settings.phoneUsageAlert')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.D700S.data.DMSPhoneDisabled"
                                                   :active-value="0" :inactive-value="1"
                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <div class="ps-dms-option">{{$t('settings.phoneUsageAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.D700S.data.DMSPhoneAudioAlert"
                                                     :disabled="deviceSettings.D700S.data.DMSPhoneDisabled !== 0"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>


                                <div class="ps-dms-option-row-level3 padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <div class="ps-name-level2">
                                        {{$t('settings.smokingAlert')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.D700.data.DMSSmokingDisabled"
                                                   :active-value="0" :inactive-value="1"

                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <div class="ps-dms-option">{{$t('settings.smokingAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.D700S.data.DMSSmokingAudioAlert"
                                                     :disabled="deviceSettings.D700S.data.DMSSmokingDisabled !== 0"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div v-show="deviceSettings.activeSetting === 'M800'"
                     class="ps-mobile360-d700 ps-mobile360-d700-height slide_top">
                    <div class="ps-ai-left-box">
                        <div class="ps-top">
                            <div class="ps-ldw">
                                <div class="ps-ldw-row">
                                    <div class="ps-name">
                                        <setting-lock v-model="isLocked_test"/>
                                        {{$t('settings.LDW')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.M800.data.LDW"
                                                   :active-value="1" :inactive-value="0"
                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>
                                <div class="ps-ldw-row padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option"
                                           :class="{'checkbox-grey': deviceSettings.M800.data.LDW !== 1}">
                                        <span class="ps-ldw-audio-alert">{{$t('settings.LDWAudioAlert')}}</span>
                                        <el-checkbox v-model="deviceSettings.M800.data.LDWAudioAlert"
                                                     :disabled="deviceSettings.M800.data.LDW !== 1"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>


                            </div>
                        </div>
                        <div class="ps-bottom">
                            <div class="ps-fcw-row item-padding">
                                <div class="ps-name">
                                    <setting-lock v-model="isLocked_test"/>
                                    {{$t('settings.FCW')}}
                                </div>
                                <div class="ps-switch">
                                    <el-switch v-model="deviceSettings.M800.data.FCW"
                                               :active-value="1" :inactive-value="0"
                                               active-color="#6995D6" inactive-color="#909090"/>
                                </div>
                            </div>
                            <div class="ps-ldw-row padding-left-space item-padding">
                                <setting-lock v-model="isLocked_test"/>
                                <label class="ps-label-option"
                                       :class="{'checkbox-grey': deviceSettings.M800.data.FCW !== 1}">
                                    <span class="ps-ldw-audio-alert">{{$t('settings.FCWAudioAlert')}}</span>
                                    <el-checkbox v-model="deviceSettings.M800.data.FCWAudioAlert"
                                                 :disabled="deviceSettings.M800.data.FCW !== 1"
                                                 :true-label="1" :false-label="0"/>
                                </label>
                            </div>

                            <div class="ps-ldw-row padding-left-space item-padding"
                                 :class="{'checkbox-grey': deviceSettings.M800.data.FCW !== 1}">
                                <setting-lock v-model="isLocked_test"/>
                                {{$t('settings.FCWAlertSensitivity')}}
                                <div class="ps-ldw-slider">
                                    <el-slider class="ps-box  ps-slider ps-slider-width" size="mini"
                                               v-model="M800FCWLevel"
                                               :marks="FCWAlertSensitivityMarks"
                                               :disabled="deviceSettings.M800.data.FCW !== 1"
                                               :min="0" :max="2" :step="1" :show-tooltip="false"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ps-ai-right-box">
                        <div class="ps-top">
                            <div class="ps-fcw ps-fcw-padding">


                                <div class="ps-dms-row">
                                    <div class="ps-name">
                                        <setting-lock v-model="isLocked_test"/>
                                        {{$t('settings.DMS')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.M800.data.DMS"
                                                   @change="onM800DMSSwitchChanged"
                                                   :active-value="1" :inactive-value="0"
                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>


                                <div class="ps-dms-option-row-level3 padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <div class="ps-name-level2">
                                        {{$t('settings.distractedDrivingAlert')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.M800.data.DMSDistractedDisabled"
                                                   :active-value="0" :inactive-value="1"
                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option"
                                           :class="{'checkbox-grey': deviceSettings.M800.data.DMS !== 1}">
                                        <div class="ps-dms-option">{{$t('settings.distractedDrivingAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.M800.data.DMSDistractedAudioAlert"
                                                     :disabled="deviceSettings.M800.data.DMSDistractedDisabled !== 0"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>


                                <div class="ps-dms-option-row-level3 padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <div class="ps-name-level2">
                                        {{$t('settings.driverFatigueAlert')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.M800.data.DMSFatigueDisabled"
                                                   :active-value="0" :inactive-value="1"
                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>

                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option"
                                           :class="{'checkbox-grey': deviceSettings.M800.data.DMS !== 1}">
                                        <div class="ps-dms-option">{{$t('settings.driverFatigueAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.M800.data.DMSFatigueAudioAlert"
                                                     :disabled="deviceSettings.M800.data.DMSFatigueDisabled !== 0"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>


                                <div class="ps-dms-option-row-level3 padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <div class="ps-name-level2">
                                        {{$t('settings.phoneUsageAlert')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.M800.data.DMSPhoneDisabled"
                                                   :active-value="0" :inactive-value="1"
                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option"
                                           :class="{'checkbox-grey': deviceSettings.M800.data.DMS !== 1}">
                                        <div class="ps-dms-option">{{$t('settings.phoneUsageAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.M800.data.DMSPhoneAudioAlert"
                                                     :disabled="deviceSettings.M800.data.DMSPhoneDisabled !== 0"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>


                                <div class="ps-dms-option-row-level3 padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <div class="ps-name-level2">
                                        {{$t('settings.smokingAlert')}}
                                    </div>
                                    <div class="ps-switch">
                                        <el-switch v-model="deviceSettings.M800.data.DMSSmokingDisabled"
                                                   :active-value="0" :inactive-value="1"
                                                   active-color="#6995D6" inactive-color="#909090"/>
                                    </div>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option"
                                           :class="{'checkbox-grey': deviceSettings.M800.data.DMS !== 1}">
                                        <div class="ps-dms-option">{{$t('settings.smokingAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.M800.data.DMSSmokingAudioAlert"
                                                     :disabled="deviceSettings.M800.data.DMSSmokingDisabled !== 0"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div v-show="deviceSettings.activeSetting === 'M810'"
                     class="ps-mobile360-d700 ps-mobile360-m810-height">
                    <div class="ps-ai-left-box" style="border: none">
                        <div class="ps-top">
                            <div class="ps-ldw">
                                <div class="ps-ldw-row padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <span class="ps-ldw-audio-alert">{{$t('settings.LDWAudioAlert')}}</span>
                                        <el-checkbox v-model="deviceSettings.M810.data.LDWAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>

                                <div class="ps-fcw-row padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <span class="ps-fcw-audio-alert">{{$t('settings.FCWAudioAlert')}}</span>
                                        <el-checkbox v-model="deviceSettings.M810.data.FCWAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>

                                <div class="ps-fcw-row padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    {{$t('settings.FCWAlertSensitivity')}}
                                    <div class="ps-fcw-slider">
                                        <el-slider class="ps-box  ps-slider ps-slider-width" size="mini"
                                                   v-model="M810FCWLevel"
                                                   :marks="FCWAlertSensitivityMarks"
                                                   :min="0" :max="2" :step="1" :show-tooltip="false"/>
                                    </div>
                                </div>

                                <div class="ps-fcw-row padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <span class="ps-fcw-audio-alert">{{$t('settings.bsdAudioAlert')}}</span>
                                        <el-checkbox v-model="deviceSettings.M810.data.BSDAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ps-ai-right-box ps-bottom">
                        <div class="ps-top left-border">
                            <div class="ps-fcw ps-fcw-padding">
                                <div class="ps-dms-row">
                                    <div class="ps-name">
                                        <setting-lock v-model="isLocked_test"/>
                                        {{$t('settings.DMS')}}
                                    </div>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <div class="ps-dms-option">{{$t('settings.distractedDrivingAudioAlert')}}</div>
                                        <el-checkbox
                                                v-model="deviceSettings.M810.data.DMSDistractedAudioAlert"
                                                :true-label="1" :false-label="0"/>
                                    </label>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <div class="ps-dms-option">{{$t('settings.driverFatigueAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.M810.data.DMSFatigueAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <div class="ps-dms-option">{{$t('settings.phoneUsageAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.M810.data.DMSPhoneAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <div class="ps-dms-option">{{$t('settings.smokingAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.M810.data.DMSSmokingAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="deviceSettings.activeSetting === 'M820'"
                     class="ps-mobile360-d700 ps-mobile360-m810-height">
                    <div class="ps-ai-left-box" style="border: none">
                        <div class="ps-top">
                            <div class="ps-ldw">
                                <div class="ps-ldw-row padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <span class="ps-ldw-audio-alert">{{$t('settings.LDWAudioAlert')}}</span>
                                        <el-checkbox v-model="deviceSettings.M820.data.LDWAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>

                                <div class="ps-fcw-row padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <span class="ps-fcw-audio-alert">{{$t('settings.FCWAudioAlert')}}</span>
                                        <el-checkbox v-model="deviceSettings.M820.data.FCWAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>

                                <div class="ps-fcw-row padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    {{$t('settings.FCWAlertSensitivity')}}
                                    <div class="ps-fcw-slider">
                                        <el-slider class="ps-box  ps-slider ps-slider-width" size="mini"
                                                   v-model="M820FCWLevel"
                                                   :marks="FCWAlertSensitivityMarks"
                                                   :min="0" :max="2" :step="1" :show-tooltip="false"/>
                                    </div>
                                </div>

                                <div class="ps-fcw-row padding-left-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <span class="ps-fcw-audio-alert">{{$t('settings.bsdAudioAlert')}}</span>
                                        <el-checkbox v-model="deviceSettings.M820.data.BSDAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ps-ai-right-box ps-bottom">
                        <div class="ps-top left-border">
                            <div class="ps-fcw ps-fcw-padding">
                                <div class="ps-dms-row">
                                    <div class="ps-name">
                                        <setting-lock v-model="isLocked_test"/>
                                        {{$t('settings.DMS')}}
                                    </div>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <div class="ps-dms-option">{{$t('settings.distractedDrivingAudioAlert')}}</div>
                                        <el-checkbox
                                                v-model="deviceSettings.M820.data.DMSDistractedAudioAlert"
                                                :true-label="1" :false-label="0"/>
                                    </label>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <div class="ps-dms-option">{{$t('settings.driverFatigueAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.M820.data.DMSFatigueAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <div class="ps-dms-option">{{$t('settings.phoneUsageAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.M820.data.DMSPhoneAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>
                                <div class="ps-dms-option-row padding-left-level3-space">
                                    <setting-lock v-model="isLocked_test"/>
                                    <label class="ps-label-option">
                                        <div class="ps-dms-option">{{$t('settings.smokingAudioAlert')}}</div>
                                        <el-checkbox v-model="deviceSettings.M820.data.DMSSmokingAudioAlert"
                                                     :true-label="1" :false-label="0"/>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:right>
                <div v-show="deviceSettings.activeSetting === 'D700'">
                    <div class="ps-mobile360-d700 ps-mobile360-d700-height">
                        <div class="ps-version">
                            {{$t('settings.currentFirmwareVersion')}}:{{deviceSettings.D700.data.FirmwareVersion.CurrentVersion.Version}}
                        </div>
                        <div class="ps-upgrade" v-if="D700HasNewVersion">
                            <div class="ps-msg">
                                <div class="ps-row ps-new-version">{{$t('settings.newVersionAvailable')}}</div>
                                <div class="ps-row">{{$t('settings.version', {value:
                                    deviceSettings.D700.data.FirmwareVersion.LatestVersion.Version})}}
                                </div>
                            </div>
                            <div class="ps-upgrade-btn">
                                <el-button type="primary" @click="openUpgradeFirmwareWindow">
                                    <i class="ps-icon via-upgrade"/>{{$t('settings.upgrade')}}
                                </el-button>
                            </div>
                        </div>
                        <div class="ps-version-detail-d700"
                             :class="{'ps-version-detail-no-upgrade': !D700HasNewVersion}">
                            <!--pre标签包裹的内容不能换行，否则首行文字出现大量空白-->
                            <pre>{{$t('settings.releaseNotes')}}:<br/>{{D700HasNewVersion ? deviceSettings.D700.data.FirmwareVersion.LatestVersion.ReleaseNote : deviceSettings.D700.data.FirmwareVersion.CurrentVersion.ReleaseNote}}</pre>
                        </div>
                    </div>
                </div>
                <div v-show="deviceSettings.activeSetting === 'D700S'">
                    <div class="ps-mobile360-d700 ps-mobile360-d700-height">
                        <div class="ps-version">
                            {{$t('settings.currentFirmwareVersion')}}:{{deviceSettings.D700S.data.FirmwareVersion.CurrentVersion.Version}}
                        </div>
                        <div class="ps-upgrade" v-if="D700SHasNewVersion">
                            <div class="ps-msg">
                                <div class="ps-row ps-new-version">{{$t('settings.newVersionAvailable')}}</div>
                                <div class="ps-row">{{$t('settings.version', {value:
                                    deviceSettings.D700S.data.FirmwareVersion.LatestVersion.Version})}}
                                </div>
                            </div>
                            <div class="ps-upgrade-btn">
                                <el-button type="primary" @click="openUpgradeFirmwareWindow">
                                    <i class="ps-icon via-upgrade"/>{{$t('settings.upgrade')}}
                                </el-button>
                            </div>
                        </div>
                        <div class="ps-version-detail-d700"
                             :class="{'ps-version-detail-no-upgrade': !D700SHasNewVersion}">
                            <!--pre标签包裹的内容不能换行，否则首行文字出现大量空白-->
                            <pre>{{$t('settings.releaseNotes')}}:<br/>{{D700SHasNewVersion ? deviceSettings.D700S.data.FirmwareVersion.LatestVersion.ReleaseNote : deviceSettings.D700S.data.FirmwareVersion.CurrentVersion.ReleaseNote}}</pre>
                        </div>
                    </div>
                </div>
                <div v-show="deviceSettings.activeSetting === 'M800'">
                    <div class="ps-mobile360-d700 ps-mobile360-m810-height">
                        <div class="ps-version">
                            {{$t('settings.currentFirmwareVersion')}}:{{deviceSettings.M800.data.FirmwareVersion.CurrentVersion.Version}}
                        </div>
                        <div class="ps-upgrade" v-if="M800HasNewVersion">
                            <div class="ps-msg">
                                <div class="ps-row ps-new-version">{{$t('settings.newVersionAvailable')}}</div>
                                <div class="ps-row">{{$t('settings.version', {value:
                                    deviceSettings.M800.data.FirmwareVersion.LatestVersion.Version})}}
                                </div>
                            </div>
                            <div class="ps-upgrade-btn">
                                <el-button type="primary" @click="openUpgradeFirmwareWindow">
                                    <i class="ps-icon via-upgrade"/>{{$t('settings.upgrade')}}
                                </el-button>
                            </div>
                        </div>
                        <div class="ps-version-detail-m810"
                             :class="{'ps-version-detail-no-upgrade-m810': !M800HasNewVersion}">
                            <!--pre标签包裹的内容不能换行，否则首行文字出现大量空白-->
                            <pre>{{$t('settings.releaseNotes')}}:<br/>{{M810HasNewVersion ? deviceSettings.M800.data.FirmwareVersion.LatestVersion.ReleaseNote.trim() : deviceSettings.M800.data.FirmwareVersion.CurrentVersion.ReleaseNote.trim()}}</pre>
                        </div>
                    </div>
                </div>
                <div v-show="deviceSettings.activeSetting === 'M810'">
                    <div class="ps-mobile360-d700 ps-mobile360-m810-height">
                        <div class="ps-version">
                            {{$t('settings.currentFirmwareVersion')}}:{{deviceSettings.M810.data.FirmwareVersion.CurrentVersion.Version}}
                        </div>
                        <div class="ps-upgrade" v-if="M810HasNewVersion">
                            <div class="ps-msg">
                                <div class="ps-row ps-new-version">{{$t('settings.newVersionAvailable')}}</div>
                                <div class="ps-row">{{$t('settings.version', {value:
                                    deviceSettings.M810.data.FirmwareVersion.LatestVersion.Version})}}
                                </div>
                            </div>
                            <div class="ps-upgrade-btn">
                                <el-button type="primary" @click="openUpgradeFirmwareWindow">
                                    <i class="ps-icon via-upgrade"/>{{$t('settings.upgrade')}}
                                </el-button>
                            </div>
                        </div>
                        <div class="ps-version-detail-m810"
                             :class="{'ps-version-detail-no-upgrade-m810': !M810HasNewVersion}">
                            <!--pre标签包裹的内容不能换行，否则首行文字出现大量空白-->
                            <pre>{{$t('settings.releaseNotes')}}:<br/>{{M810HasNewVersion ? deviceSettings.M810.data.FirmwareVersion.LatestVersion.ReleaseNote.trim() : deviceSettings.M810.data.FirmwareVersion.CurrentVersion.ReleaseNote.trim()}}</pre>
                        </div>
                    </div>
                </div>
                <div v-show="deviceSettings.activeSetting === 'M820'">
                    <div class="ps-mobile360-d700 ps-mobile360-m810-height">
                        <div class="ps-version">
                            {{$t('settings.currentFirmwareVersion')}}:{{deviceSettings.M820.data.FirmwareVersion.CurrentVersion.Version}}
                        </div>
                        <div class="ps-upgrade" v-if="M820HasNewVersion">
                            <div class="ps-msg">
                                <div class="ps-row ps-new-version">{{$t('settings.newVersionAvailable')}}</div>
                                <div class="ps-row">{{$t('settings.version', {value:
                                    deviceSettings.M820.data.FirmwareVersion.LatestVersion.Version})}}
                                </div>
                            </div>
                            <div class="ps-upgrade-btn">
                                <el-button type="primary" @click="openUpgradeFirmwareWindow">
                                    <i class="ps-icon via-upgrade"/>{{$t('settings.upgrade')}}
                                </el-button>
                            </div>
                        </div>
                        <div class="ps-version-detail-m810"
                             :class="{'ps-version-detail-no-upgrade-m810': !M820HasNewVersion}">
                            <!--pre标签包裹的内容不能换行，否则首行文字出现大量空白-->
                            <pre>{{$t('settings.releaseNotes')}}:<br/>{{M820HasNewVersion ? deviceSettings.M820.data.FirmwareVersion.LatestVersion.ReleaseNote.trim() : deviceSettings.M820.data.FirmwareVersion.CurrentVersion.ReleaseNote.trim()}}</pre>
                        </div>
                    </div>
                </div>
            </template>
        </device-settings-layout>


        <div class="sl-row sl-top" v-show="showDeviceSettings">
            <div class="sl-left">
                <block-box :header="$t('settings.audioSettings')">
                    <div class="alert_setting">
                        <div class="ps-global-setting-row">
                            <div class="ps-left">
                                <setting-lock v-model="isLocked_test"/>
                                {{$t('settings.way2CallVolume')}}
                            </div>
                            <div class="ps-right">
                                <el-slider class="ps-box ps-min-max  ps-slider ps-slider-width" size="mini"
                                           v-model="callVolume"
                                           :marks="callVolumeMarks"
                                           @change="onCallVolumeChanged"
                                           :min="0" :max="4" :step="1" :show-tooltip="false"/>
                            </div>
                        </div>

                        <div class="ps-global-setting-row" style="margin-bottom: 25px">
                            <div class="ps-left">
                                <setting-lock v-model="isLocked_test"/>
                                {{$t('settings.alertVolume')}}
                            </div>
                            <div class="ps-right">
                                <el-slider class="ps-box ps-min-max  ps-slider ps-slider-width" size="mini"
                                           v-model="alertVolume"
                                           :marks="alertVolumeMarks"
                                           @change="onAlertVolumeChanged"
                                           :min="0" :max="4" :step="1" :show-tooltip="false"/>
                            </div>
                        </div>
                    </div>
                </block-box>
            </div>
        </div>

        <div class="ps-action-btns">
            <el-button size="small" class="ps-btn" type="primary" :loading="btnDisabledStatus"
                       @click="openApplySettings">{{$t('globalText.save')}}
            </el-button>
            <el-button size="small" class="ps-btn" type="info" :disabled="btnDisabledStatus"
                       @click="handleCancelAllSettings">{{$t('globalText.cancel')}}
            </el-button>
        </div>

        <apply-settings v-if="isOpenApplySettingWindow"
                        :root-fleet="rootFleetInfo"
                        :fleet-list="subFleetList"
                        @updateSettings="handleUpdateSettings"
                        @close="handleCloseApplySettingWindow"/>

        <upgrade-firmware
                v-if="isOpenUpgradeFirmware"
                :device-name="deviceSettings.currentDeviceName"
                :main-model="deviceSettings.currentMainModel"
                :version="deviceSettings.currentVersion"
                :close-loading="closeUpgradeFirmWareLoading"
                @upgrade="handleUpgradeFirmware"
                @close="closeUpgradeFirmwareWindow"/>
    </div>
</template>

<script>
    import GlobalSettingsLayout from "../../components/settings/GlobalSettingsLayout";
    import DeviceSettingsLayout from "../../components/settings/DeviceSettingsLayout";
    import ApplySettings from "../../components/settings/ApplySettings";
    import UpgradeFirmware from "../../components/settings/UpgradeFirmware";
    import SettingLock from "../../components/settings/SettingLock";
    import BlockBox from "../../components/settings/BlockBox";

    const D700 = 'D700', M800 = 'M800', M810 = 'M810',D700S = 'D700S',M820 = 'M820';

    export default {
        name: "Index",
        components: {
            SettingLock,
            GlobalSettingsLayout,
            DeviceSettingsLayout,
            ApplySettings,
            UpgradeFirmware,
            BlockBox
        },
        data() {
            return {
                loginScanTimeTip: "",
                loginPictureDelayTip: "",
                D700FCWLevel: 0,
                D700SFCWLevel: 0,
                M800FCWLevel: 0,
                M810FCWLevel: 0,
                M820FCWLevel: 0,
                AlertLanguage: this.$i18n.locale,
                originalD700Data: null,
                originalD700SData: null,
                originalM800Data: null,
                originalM810Data: null,
                originalM820Data: null,
                lang: this.$i18n.locale,
                isLocked_test: false,
                language: [
                    {
                        value: "en-US",
                        label: "English"
                    },
                    {
                        value: "zh-CN",
                        label: "中文简体"
                    },
                    {
                        value: "zh-TW",
                        label: "中文繁體"
                    }
                ],
                alertLanguage:[
                    {
                        value: "en-US",
                        label: "English"
                    },
                    {
                        value: "ja-JP",
                        label: "日本語"
                    },
                    {
                        value: "zh-CN",
                        label: "中文简体"
                    },
                    {
                        value: "zh-TW",
                        label: "中文繁體"
                    }
                ],
                isOpenApplySettingWindow: false,
                isOpenUpgradeFirmware: false,
                closeUpgradeFirmWareLoading: new Date(),
                // company接口的信息
                apiCompanyInfo: null,
                rootFleetInfo: {},
                subFleetList: [],
                companyId: '',
                // Unit Settings
                unit: 'Metric',
                dateFormat: 'YYYY-MM-DD',
                // Video Alert Settings
                AlertRecordTime: 10,
                sensitivity: 5,
                m810GsensorEventSensitivityProxy: 5,
                GsensorEventSensitivityProxy: 5,
                d710GsensorEventSensitivityProxy: 5,
                // Driver Login Settings
                loginMethod: 0,
                scanTTL: 5,
                takePictureTime: 20,
                // Audio Settings
                muteRecord: 0,
                callVolume: 2,
                callRecord: 0,
                alertVolume: 2,
                // Device Settings
                deviceSettings: {
                    activeSetting: '',
                    currentDeviceName: '',
                    currentMainModel: '',
                    currentVersion: '',
                    D700: {
                        show: false,
                        data: {
                            AlertVolume: 2,
                            CallVolume: 2,
                            MainModel: "D700",
                            LDW: 1,
                            LDWAudioAlert: 1,
                            FCW: 1,
                            FCWAudioAlert: 1,
                            FCWLevel: 1,
                            DMS: 1,
                            DMSFatigueAudioAlert: 1,
                            DMSPhoneAudioAlert: 1,
                            DMSDistractedAudioAlert: 1,
                            DMSSmokingAudioAlert: 1,
                            DMSFatigueDisabled: 0,
                            DMSPhoneDisabled: 0,
                            DMSDistractedDisabled: 0,
                            DMSSmokingDisabled: 0,
                            FirmwareVersion: {
                                Show: false,
                                CurrentVersion: {
                                    Version: '',
                                    ReleaseNote: ''
                                },
                                LatestVersion: {
                                    Version: '',
                                    ReleaseNote: ''
                                }
                            }
                        }
                    },
                    D700S: {
                        show: false,
                        data: {
                            AlertVolume: 2,
                            CallVolume: 2,
                            MainModel: "D700S",
                            LDWAudioAlert: 1,
                            FCWAudioAlert: 1,
                            FCWLevel: 1,
                            DMSFatigueAudioAlert: 1,
                            DMSPhoneAudioAlert: 1,
                            DMSDistractedAudioAlert: 1,
                            DMSSmokingAudioAlert: 1,
                            DMSFatigueDisabled: 0,
                            DMSPhoneDisabled: 0,
                            DMSDistractedDisabled: 0,
                            DMSSmokingDisabled: 0,
                            FirmwareVersion: {
                                Show: true,
                                CurrentVersion: {
                                    Version: '',
                                    ReleaseNote: ''
                                },
                                LatestVersion: {
                                    Version: '',
                                    ReleaseNote: ''
                                }
                            }
                        }
                    },
                    M800: {
                        show: false,
                        data: {
                            AlertVolume: 2,
                            CallVolume: 2,
                            MainModel: "M800",
                            LDW: 0,
                            LDWAudioAlert: 0,
                            FCW: 0,
                            FCWAudioAlert: 0,
                            FCWLevel: 0,
                            DMS: 1,
                            DMSFatigueAudioAlert: 1,
                            DMSPhoneAudioAlert: 1,
                            DMSDistractedAudioAlert: 1,
                            DMSSmokingAudioAlert: 1,
                            DMSFatigueDisabled: 0,
                            DMSPhoneDisabled: 0,
                            DMSDistractedDisabled: 0,
                            DMSSmokingDisabled: 0,
                            FirmwareVersion: {
                                Show: false,
                                CurrentVersion: {
                                    Version: '',
                                    ReleaseNote: ''
                                },
                                LatestVersion: {
                                    Version: '',
                                    ReleaseNote: ''
                                }
                            }
                        }
                    },
                    M810: {
                        show: false,
                        data: {
                            AlertVolume: 2,
                            CallVolume: 2,
                            MainModel: "M810",
                            LDWAudioAlert: 1,
                            FCWAudioAlert: 1,
                            FCWLevel: 1,
                            BSDAudioAlert: 1,
                            DMSFatigueAudioAlert: 1,
                            DMSPhoneAudioAlert: 1,
                            DMSDistractedAudioAlert: 1,
                            DMSSmokingAudioAlert: 1,
                            FirmwareVersion: {
                                Show: false,
                                CurrentVersion: {
                                    Version: '',
                                    ReleaseNote: ''
                                },
                                LatestVersion: {
                                    Version: '',
                                    ReleaseNote: ''
                                }
                            }
                        }
                    },
                    M820: {
                        show: false,
                        data: {
                            AlertVolume: 2,
                            CallVolume: 2,
                            MainModel: "M820",
                            LDWAudioAlert: 1,
                            FCWAudioAlert: 1,
                            FCWLevel: 1,
                            BSDAudioAlert: 1,
                            DMSFatigueAudioAlert: 1,
                            DMSPhoneAudioAlert: 1,
                            DMSDistractedAudioAlert: 1,
                            DMSSmokingAudioAlert: 1,
                            FirmwareVersion: {
                                Show: false,
                                CurrentVersion: {
                                    Version: '',
                                    ReleaseNote: ''
                                },
                                LatestVersion: {
                                    Version: '',
                                    ReleaseNote: ''
                                }
                            }
                        }
                    }
                },
                btnDisabledStatus: false,
                loadingMask: null,
            }
        },
        created() {
            this.getCompanyInfoFromApi().then(data => {
                this.apiCompanyInfo = data;
                this.resetAllSettings(data);
            }).catch(error => {
                this.$message({message: this.$t('globalText.requestTimeout'), type: 'warning'});
            });
        },
        computed: {
            D700HasNewVersion() {
                let firmwareVersion = this.deviceSettings.D700.data.FirmwareVersion;
                return firmwareVersion.LatestVersion.Version !== '' && firmwareVersion.CurrentVersion.Version !== firmwareVersion.LatestVersion.Version;
            },
            D700SHasNewVersion() {
                let firmwareVersion = this.deviceSettings.D700S.data.FirmwareVersion;
                return firmwareVersion.LatestVersion.Version !== '' && firmwareVersion.CurrentVersion.Version !== firmwareVersion.LatestVersion.Version;
            },
            M800HasNewVersion() {
                let firmwareVersion = this.deviceSettings.M800.data.FirmwareVersion;
                return firmwareVersion.LatestVersion.Version !== '' && firmwareVersion.CurrentVersion.Version !== firmwareVersion.LatestVersion.Version;
            },
            M810HasNewVersion() {
                let firmwareVersion = this.deviceSettings.M810.data.FirmwareVersion;
                return firmwareVersion.LatestVersion.Version !== '' && firmwareVersion.CurrentVersion.Version !== firmwareVersion.LatestVersion.Version;
            },
            M820HasNewVersion() {
                let firmwareVersion = this.deviceSettings.M820.data.FirmwareVersion;
                return firmwareVersion.LatestVersion.Version !== '' && firmwareVersion.CurrentVersion.Version !== firmwareVersion.LatestVersion.Version;
            },
            isShowFirmwareUpgrade() {
                return (this.deviceSettings.activeSetting === D700 && this.deviceSettings.D700.data.FirmwareVersion.Show)
                    || (this.deviceSettings.activeSetting === D700S && this.deviceSettings.D700S.data.FirmwareVersion.Show)
                    || (this.deviceSettings.activeSetting === M800 && this.deviceSettings.M800.data.FirmwareVersion.Show)
                    || (this.deviceSettings.activeSetting === M810 && this.deviceSettings.M810.data.FirmwareVersion.Show)
                    || (this.deviceSettings.activeSetting === M820 && this.deviceSettings.M820.data.FirmwareVersion.Show);
            },
            showDeviceSettings() {
                if (!this.apiCompanyInfo) return false;
                let deviceSettings = this.apiCompanyInfo.DeviceSettings;
                return !!deviceSettings && (!!deviceSettings.D700 || !!deviceSettings.M810 || !!deviceSettings.M820);
            },
            callVolumeMarks() {
                return {
                    0: this.$t('settings.min'),
                    4: this.$t('settings.max')
                };
            },
            alertVolumeMarks() {
                return {
                    0: this.$t('settings.min'),
                    4: this.$t('settings.max')
                };
            },
            alertRecordingOptions() {
                return [
                    {label: '10', value: 10},
                    {label: '20', value: 20},
                    {label: '30', value: 30}
                ];
            },
            sensitivityMarks() {
                return {
                    5: this.$t('settings.low'),
                    12.5: {
                        style: {
                            marginLeft: '-5px'
                        },
                        label: this.$t('settings.medium')
                    },
                    20: this.$t('settings.high')
                }
            },
            FCWAlertSensitivityMarks() {
                return {
                    0: this.$t('settings.low'),
                    1: this.$t('settings.medium'),
                    2: this.$t('settings.high')
                }
            },
            secs() {
                return this.$t('settings.secs');
            },
            unitOptions() {
                return [
                    {label: this.$t('settings.metric'), value: 'Metric'},
                    {label: this.$t('settings.imperial'), value: 'Imperial'},
                    {label: this.$t('settings.USCustomary'), value: 'US Customary'}
                ];
            },
            timeFormatOptions() {
                return [
                    {label: this.$t('settings.YYYY_MM_DD'), value: 'YYYY-MM-DD'},
                    {label: this.$t('settings.MM_DD_YYYY'), value: 'MM-DD-YYYY'},
                    {label: this.$t('settings.DD_MM_YYYY'), value: 'DD-MM-YYYY'}
                ]
            }
        },
        watch: {
            isLocked(value) {
                console.log(value, 'index')
            },
            'deviceSettings.D700.data.FCW'(fcw) {
                this.deviceSettings.D700.data.FCWAudioAlert = fcw;
                let dms = this.deviceSettings.D700.data.DMS;
                if (fcw === 1 && dms === 1)
                    this.deviceSettings.D700.data.DMS = 0;
            },
            'deviceSettings.D700.data.DMS'(dms) {
                let fcw = this.deviceSettings.D700.data.FCW;
                if (fcw === 1 && dms === 1)
                    this.deviceSettings.D700.data.FCW = 0;
                if (dms === 0) {
                    //二级功能项开关比较特殊，0--开启，1--关闭
                    //关闭二级功能项
                    this.deviceSettings.D700.data.DMSFatigueDisabled = 1;
                    this.deviceSettings.D700.data.DMSPhoneDisabled = 1;
                    this.deviceSettings.D700.data.DMSDistractedDisabled = 1;
                    this.deviceSettings.D700.data.DMSSmokingDisabled = 1;
                    //关闭三级功能项
                    this.deviceSettings.D700.data.DMSFatigueAudioAlert = 0;
                    this.deviceSettings.D700.data.DMSPhoneAudioAlert = 0;
                    this.deviceSettings.D700.data.DMSDistractedAudioAlert = 0;
                    this.deviceSettings.D700.data.DMSSmokingAudioAlert = 0;

                }
            },
            'deviceSettings.M800.data.DMS'(dms) {
                if (dms === 0) {
                    //二级功能项开关比较特殊，0--开启，1--关闭
                    //关闭二级功能项
                    this.deviceSettings.M800.data.DMSFatigueDisabled = 1;
                    this.deviceSettings.M800.data.DMSPhoneDisabled = 1;
                    this.deviceSettings.M800.data.DMSDistractedDisabled = 1;
                    this.deviceSettings.M800.data.DMSSmokingDisabled = 1;
                    //关闭三级功能项
                    this.deviceSettings.M800.data.DMSFatigueAudioAlert = 0;
                    this.deviceSettings.M800.data.DMSPhoneAudioAlert = 0;
                    this.deviceSettings.M800.data.DMSDistractedAudioAlert = 0;
                    this.deviceSettings.M800.data.DMSSmokingAudioAlert = 0;
                }
            },

            'deviceSettings.D700.data.DMSDistractedDisabled'(value) {
                if (value === 1) {
                    this.deviceSettings.D700.data.DMSDistractedAudioAlert = 0;
                } else if (value === 0) {
                    this.deviceSettings.D700.data.DMSDistractedAudioAlert = 1;
                }
                this.checkDMSState(D700);
            },
            'deviceSettings.D700.data.DMSFatigueDisabled'(value) {
                if (value === 1) {
                    this.deviceSettings.D700.data.DMSFatigueAudioAlert = 0;
                } else if (value === 0) {
                    this.deviceSettings.D700.data.DMSFatigueAudioAlert = 1;
                }
                this.checkDMSState(D700);
            },
            'deviceSettings.D700.data.DMSPhoneDisabled'(value) {
                if (value === 1) {
                    this.deviceSettings.D700.data.DMSPhoneAudioAlert = 0;
                } else if (value === 0) {
                    this.deviceSettings.D700.data.DMSPhoneAudioAlert = 1;
                }
                this.checkDMSState(D700);
            },
            'deviceSettings.D700.data.DMSSmokingDisabled'(value) {
                if (value === 1) {
                    this.deviceSettings.D700.data.DMSSmokingAudioAlert = 0;
                } else if (value === 0) {
                    this.deviceSettings.D700.data.DMSSmokingAudioAlert = 1;
                }
                this.checkDMSState(D700);
            },

            'deviceSettings.D700S.data.DMSDistractedDisabled'(value) {
                if (value === 1) {
                    this.deviceSettings.D700S.data.DMSDistractedAudioAlert = 0;
                } else if (value === 0) {
                    this.deviceSettings.D700S.data.DMSDistractedAudioAlert = 1;
                }
            },
            'deviceSettings.D700S.data.DMSFatigueDisabled'(value) {
                if (value === 1) {
                    this.deviceSettings.D700S.data.DMSFatigueAudioAlert = 0;
                } else if (value === 0) {
                    this.deviceSettings.D700S.data.DMSFatigueAudioAlert = 1;
                }
            },
            'deviceSettings.D700S.data.DMSPhoneDisabled'(value) {
                if (value === 1) {
                    this.deviceSettings.D700S.data.DMSPhoneAudioAlert = 0;
                } else if (value === 0) {
                    this.deviceSettings.D700S.data.DMSPhoneAudioAlert = 1;
                }
            },
            'deviceSettings.D700S.data.DMSSmokingDisabled'(value) {
                if (value === 1) {
                    this.deviceSettings.D700S.data.DMSSmokingAudioAlert = 0;
                } else if (value === 0) {
                    this.deviceSettings.D700S.data.DMSSmokingAudioAlert = 1;
                }
            },

            'deviceSettings.M800.data.DMSDistractedDisabled'(value) {
                if (value === 1) {
                    this.deviceSettings.M800.data.DMSDistractedAudioAlert = 0;
                } else if (value === 0) {
                    this.deviceSettings.M800.data.DMSDistractedAudioAlert = 1;
                }
                this.checkDMSState(M800);
            },
            'deviceSettings.M800.data.DMSFatigueDisabled'(value) {
                if (value === 1) {
                    this.deviceSettings.M800.data.DMSFatigueAudioAlert = 0;
                } else if (value === 0) {
                    this.deviceSettings.M800.data.DMSFatigueAudioAlert = 1;
                }
                this.checkDMSState(M800);
            },
            'deviceSettings.M800.data.DMSPhoneDisabled'(value) {
                if (value === 1) {
                    this.deviceSettings.M800.data.DMSPhoneAudioAlert = 0;
                } else if (value === 0) {
                    this.deviceSettings.M800.data.DMSPhoneAudioAlert = 1;
                }
                this.checkDMSState(M800);
            },
            'deviceSettings.M800.data.DMSSmokingDisabled'(value) {
                if (value === 1) {
                    this.deviceSettings.M800.data.DMSSmokingAudioAlert = 0;
                } else if (value === 0) {
                    this.deviceSettings.M800.data.DMSSmokingAudioAlert = 1;
                }
                this.checkDMSState(M800);
            },
            'deviceSettings.M800.data.LDW'(value){
                this.deviceSettings.M800.data.LDWAudioAlert = value;
            },
            'deviceSettings.D700.data.LDW'(value){
                this.deviceSettings.D700.data.LDWAudioAlert = value;
            },
            'deviceSettings.M800.data.FCW'(value){
                this.deviceSettings.M800.data.FCWAudioAlert = value;
            }

        },
        methods: {
            onInputFocus(){
                this.loginScanTimeTip = this.$t('globalText.theValueShouldBeBetween_X_And_Y', {min: 5, max: 300});
            },
            onInputBlur(){
                this.loginScanTimeTip = "";
            },
            onLoginPictureInputFocus(){
                this.loginPictureDelayTip = this.$t('globalText.theValueShouldBeBetween_X_And_Y', {min: 20, max: 300});
            },
            onLoginPictureInputBlur(){
                this.loginPictureDelayTip = "";
            },
            onD700DMSSwitchChanged: function (dms) {
                let fcw = this.deviceSettings.D700.data.FCW;
                if (fcw === 1 && dms === 1)
                    this.deviceSettings.D700.data.FCW = 0;
                if (dms === 1) {
                    this.deviceSettings.D700.data.DMSFatigueDisabled = 0;
                    this.deviceSettings.D700.data.DMSPhoneDisabled = 0;
                    this.deviceSettings.D700.data.DMSDistractedDisabled = 0;
                    this.deviceSettings.D700.data.DMSSmokingDisabled = 0;
                    //打开三级功能项
                    this.deviceSettings.D700.data.DMSFatigueAudioAlert = 1;
                    this.deviceSettings.D700.data.DMSPhoneAudioAlert = 1;
                    this.deviceSettings.D700.data.DMSDistractedAudioAlert = 1;
                    this.deviceSettings.D700.data.DMSSmokingAudioAlert = 1;
                }

            },
            onM800DMSSwitchChanged: function (dms) {
                if (dms === 1) {
                    //开启二级功能项
                    this.deviceSettings.M800.data.DMSFatigueDisabled = 0;
                    this.deviceSettings.M800.data.DMSPhoneDisabled = 0;
                    this.deviceSettings.M800.data.DMSDistractedDisabled = 0;
                    this.deviceSettings.M800.data.DMSSmokingDisabled = 0;
                    //开启三级功能项
                    this.deviceSettings.M800.data.DMSFatigueAudioAlert = 1;
                    this.deviceSettings.M800.data.DMSPhoneAudioAlert = 1;
                    this.deviceSettings.M800.data.DMSDistractedAudioAlert = 1;
                    this.deviceSettings.M800.data.DMSSmokingAudioAlert = 1;
                }
            },
            checkDMSState(model) {
                if (model === D700) {
                    if (this.deviceSettings.D700.data.DMSSmokingDisabled === 1
                        && this.deviceSettings.D700.data.DMSDistractedDisabled === 1
                        && this.deviceSettings.D700.data.DMSFatigueDisabled === 1
                        && this.deviceSettings.D700.data.DMSPhoneDisabled === 1) {
                        this.deviceSettings.D700.data.DMS = 0;
                    }
                    if (this.deviceSettings.D700.data.DMSSmokingDisabled === 0
                        || this.deviceSettings.D700.data.DMSDistractedDisabled === 0
                        || this.deviceSettings.D700.data.DMSFatigueDisabled === 0
                        || this.deviceSettings.D700.data.DMSPhoneDisabled === 0) {
                        this.deviceSettings.D700.data.DMS = 1;
                    }
                } else if (model === M800) {
                    if (this.deviceSettings.M800.data.DMSSmokingDisabled === 1
                        && this.deviceSettings.M800.data.DMSDistractedDisabled === 1
                        && this.deviceSettings.M800.data.DMSFatigueDisabled === 1
                        && this.deviceSettings.M800.data.DMSPhoneDisabled === 1) {
                        this.deviceSettings.M800.data.DMS = 0;
                    }
                    if (this.deviceSettings.M800.data.DMSSmokingDisabled === 0
                        || this.deviceSettings.M800.data.DMSDistractedDisabled === 0
                        || this.deviceSettings.M800.data.DMSFatigueDisabled === 0
                        || this.deviceSettings.M800.data.DMSPhoneDisabled === 0) {
                        this.deviceSettings.M800.data.DMS = 1;
                    }
                }

            },
            switchLanguage(value) {
                if (value === "zh-CN") {
                    this.$i18n.locale = "zh-CN";
                } else if (value === "en-US") {
                    this.$i18n.locale = "en-US";
                } else if (value === "zh-TW") {
                    this.$i18n.locale = "zh-TW";
                }
                this.$store.commit('i18n/updateLocalLanguage', value);
            },

            handleCloseApplySettingWindow() {
                this.isOpenApplySettingWindow = false;
            },
            openApplySettings() {
                // 直接更新结果
                this.handleUpdateSettings();
            },
            setSaveBtnDisable() {
                this.btnDisabledStatus = true;
            },
            setSaveBtnAble() {
                this.btnDisabledStatus = false;
            },
            openUpgradeFirmwareWindow() {
                this.isOpenUpgradeFirmware = true;
            },
            handleUpgradeFirmware(params) {
                let syncMainModelVersion = (mainModel) => {
                    switch (mainModel) {
                        case D700:
                            this.deviceSettings.D700.data.FirmwareVersion.CurrentVersion
                                = this.deviceSettings.D700.data.FirmwareVersion.LatestVersion;
                            break;
                        case M800:
                            this.deviceSettings.M800.data.FirmwareVersion.CurrentVersion
                                = this.deviceSettings.M800.data.FirmwareVersion.LatestVersion;
                            break;
                        case M810:
                            this.deviceSettings.M810.data.FirmwareVersion.CurrentVersion
                                = this.deviceSettings.M810.data.FirmwareVersion.LatestVersion;
                            break;
                        case M820:
                            this.deviceSettings.M820.data.FirmwareVersion.CurrentVersion
                                = this.deviceSettings.M820.data.FirmwareVersion.LatestVersion;
                            break;
                        default:
                            break;
                    }
                };
                let successMsg = {
                    message: this.$t('globalText.savedSuccessfully'),
                    type: 'success'
                };
                let failedMsg = {
                    message: this.$t('globalText.failedToSave'),
                    type: 'warning'
                };
                this.updateFirmware(params).then(data => {
                    if (data && data.Result) {
                        this.closeUpgradeFirmwareWindow();
                        this.closeUpgradeFirmWareLoading = new Date();
                        this.$message(successMsg);
                        syncMainModelVersion(this.deviceSettings.currentMainModel);
                    } else {
                        console.error(`更新失败`);
                        this.closeUpgradeFirmWareLoading = new Date();
                        this.$message(failedMsg);
                    }
                }).catch(error => {
                    console.error(`更新失败`);
                    this.closeUpgradeFirmWareLoading = new Date();
                    if (error.code !== 6001)
                        this.$message(failedMsg);
                });
            },
            changeSensitivity(value) {
                this.sensitivity = 25 - value;
            },
            closeUpgradeFirmwareWindow() {
                this.isOpenUpgradeFirmware = false;
            },
            onCallVolumeChanged(value) {
                if (this.deviceSettings.currentMainModel === D700) {
                    this.deviceSettings.D700.data.CallVolume = value;
                }else if (this.deviceSettings.currentMainModel === M800) {
                    this.deviceSettings.M800.data.CallVolume = value;
                } else if (this.deviceSettings.currentMainModel === M810) {
                    this.deviceSettings.M810.data.CallVolume = value;
                }else if (this.deviceSettings.currentMainModel === M820) {
                    this.deviceSettings.M820.data.CallVolume = value;
                }
            },
            onAlertVolumeChanged(value) {
                if (this.deviceSettings.currentMainModel === D700) {
                    this.deviceSettings.D700.data.AlertVolume = value;
                }else if (this.deviceSettings.currentMainModel === M800) {
                    this.deviceSettings.M800.data.AlertVolume = value;
                } else if (this.deviceSettings.currentMainModel === M810) {
                    this.deviceSettings.M810.data.AlertVolume = value;
                }else if (this.deviceSettings.currentMainModel === M820) {
                    this.deviceSettings.M820.data.AlertVolume = value;
                }
            },
            switchActiveSetting(deviceType) {
                this.deviceSettings.activeSetting = deviceType;
                let deviceName = '', mainModel = '', version = '', callVolume = 0, alertVolume = 0;
                if (deviceType === D700) {
                    deviceName = "Mobile360 D700";
                    mainModel = D700;
                    version = this.deviceSettings.D700.data.FirmwareVersion.LatestVersion.Version;
                    callVolume = this.deviceSettings.D700.data.CallVolume;
                    alertVolume = this.deviceSettings.D700.data.AlertVolume;
                } else if (deviceType === D700S){
                    deviceName = "Mobile360 D700S"  ;
                    mainModel = D700S;
                    version = this.deviceSettings.D700S.data.FirmwareVersion.LatestVersion.Version;
                    callVolume = this.deviceSettings.D700S.data.CallVolume;
                    alertVolume = this.deviceSettings.D700S.data.AlertVolume;
                }else if (deviceType === M800) {
                    deviceName = "Mobile360 M800";
                    mainModel = M800;
                    version = this.deviceSettings.M800.data.FirmwareVersion.LatestVersion.Version;
                    callVolume = this.deviceSettings.M800.data.CallVolume;
                    alertVolume = this.deviceSettings.M800.data.AlertVolume;
                } else if (deviceType === M810) {
                    deviceName = "Mobile360 M810";
                    mainModel = M810;
                    version = this.deviceSettings.M810.data.FirmwareVersion.LatestVersion.Version;
                    callVolume = this.deviceSettings.M810.data.CallVolume;
                    alertVolume = this.deviceSettings.M810.data.AlertVolume;
                }else if (deviceType === M820) {
                    deviceName = "Mobile360 M820";
                    mainModel = M820;
                    version = this.deviceSettings.M820.data.FirmwareVersion.LatestVersion.Version;
                    callVolume = this.deviceSettings.M820.data.CallVolume;
                    alertVolume = this.deviceSettings.M820.data.AlertVolume;
                }
                this.deviceSettings.currentDeviceName = deviceName;
                this.deviceSettings.currentMainModel = mainModel;
                this.deviceSettings.currentVersion = version;
                this.callVolume = callVolume;
                this.alertVolume = alertVolume;
            },
            handleUpdateSettings() {
                this.setSaveBtnDisable();
                let settingParams = {
                    Id: this.companyId,
                    Unit: this.unit,
                    DateFormat: this.dateFormat,
                    AlertRecordTime: this.AlertRecordTime,
                    Sensitivity: this.sensitivity,
                    DriverFacialRecognition: this.loginMethod,
                    ScanTTL: this.scanTTL,
                    TakePictureTime: this.takePictureTime,
                    MuteRecord: this.muteRecord,
                    CallVolume: this.callVolume,
                    CallRecord: this.callRecord,
                    AlertVolume: this.alertVolume,
                    AlertLanguage: this.AlertLanguage
                };
                let D700deviceSettings = this.deviceSettings.D700.data,
                    D700SdeviceSettings = this.deviceSettings.D700S.data,
                    M800deviceSettings = this.deviceSettings.M800.data,
                    M810deviceSettings = this.deviceSettings.M810.data,
                    M820deviceSettings = this.deviceSettings.M820.data;
                settingParams.DeviceSettings = {};
                let isD700AIChanged = false,
                    isM800AIChanged = false,isD700SAIChanged = false;
                if (this.apiCompanyInfo && this.apiCompanyInfo.DeviceSettings.D700) {
                    D700deviceSettings.FCWLevel = 2 - this.D700FCWLevel;
                    settingParams.DeviceSettings.D700 = D700deviceSettings;
                    isD700AIChanged = this.checkIsAIFeaturesChanged(D700deviceSettings, this.originalD700Data, D700).length > 0;
                }
                if (this.apiCompanyInfo && this.apiCompanyInfo.DeviceSettings.D700S) {
                    D700SdeviceSettings.FCWLevel = 2 - this.D700SFCWLevel;
                    settingParams.DeviceSettings.D700S = D700SdeviceSettings;
                    isD700SAIChanged = this.checkIsAIFeaturesChanged(D700SdeviceSettings, this.originalD700SData, D700S).length > 0;
                }
                if (this.apiCompanyInfo && this.apiCompanyInfo.DeviceSettings.M800) {
                    M800deviceSettings.FCWLevel = 2 - this.M800FCWLevel;
                    settingParams.DeviceSettings.M800 = M800deviceSettings;
                    isM800AIChanged = this.checkIsAIFeaturesChanged(M800deviceSettings, this.originalM800Data, M800).length > 0;
                }
                if (this.apiCompanyInfo && this.apiCompanyInfo.DeviceSettings.M810) {
                    M810deviceSettings.FCWLevel = 2 - this.M810FCWLevel;
                    settingParams.DeviceSettings.M810 = M810deviceSettings;
                    //M810没有LDW、FCW、DMS三个开关选项，所有不会存在变动情况，但存在DMS四个功能组开关变动。
                    // 工作项43724要求四个功能开关也不在web端做变动，因此M810不会出现变动提醒情况
                    //isM810AIChanged = this.checkIsAIFeaturesChanged(M810deviceSettings, this.originalM810Data, M810).length > 0;
                }
                if (this.apiCompanyInfo && this.apiCompanyInfo.DeviceSettings.M820) {
                    M820deviceSettings.FCWLevel = 2 - this.M820FCWLevel;
                    settingParams.DeviceSettings.M820 = M820deviceSettings;
                }

                if (isD700AIChanged || isM800AIChanged || isD700SAIChanged) {
                    let AIChangedDevice = '';
                    if (isD700AIChanged) AIChangedDevice += 'D700 ';
                    if (isD700SAIChanged) AIChangedDevice += 'D700S ';
                    if (isM800AIChanged) AIChangedDevice += 'M800 ';

                    let msg = this.$t('popUpMsg.settings.aiSettingChanged', {deviceModel: AIChangedDevice});
                    this.$customConfirm(msg, {type: 'warning'})
                        .then(() => {
                            this.uploadSettings(settingParams)
                        })
                        .catch(() => {
                            this.setSaveBtnAble();
                        })
                } else {
                    this.uploadSettings(settingParams)
                }

            },
            uploadSettings(settingParams) {
                this.updateCompanyInfo(settingParams).then(() => {
                    this.setSaveBtnAble();
                    this.$message({message: this.$t('globalText.savedSuccessfully'), type: 'success'});
                }).catch(error => {
                    this.setSaveBtnAble();
                    if (error.code !== 6001)
                        this.$message({message: this.$t('globalText.failedToSave'), type: 'warning'});
                });
            },

            //仅限于LDW、FCW、DMS三个开关的变动，视为AI设置变化
            //DMS四个功能项开关状态变动也视作AI设置变化---2021/2/24
            checkIsAIFeaturesChanged(data, originalData, type) {
                if (!data) return [];
                let changedKeyArr = [];
                if (type === D700 || type === M800) {
                    //M810,D700S没有以下三个开关，不做判断
                    if (data.LDW !== originalData.LDW) changedKeyArr.push('LDW');
                    if (data.FCW !== originalData.FCW) changedKeyArr.push('FCW');
                    if (data.DMS !== originalData.DMS) changedKeyArr.push('DMS');
                }

                if (type === D700 || type === M800 || type === D700S) {
                    //M810没有以下四个开关，不做判断
                    if (data.DMSFatigueDisabled !== originalData.DMSFatigueDisabled) changedKeyArr.push('DMS_Fatigue');
                    if (data.DMSPhoneDisabled !== originalData.DMSPhoneDisabled) changedKeyArr.push('DMS_Phone');
                    if (data.DMSDistractedDisabled !== originalData.DMSDistractedDisabled) changedKeyArr.push('DMS_Distract');
                    if (data.DMSSmokingDisabled !== originalData.DMSSmokingDisabled) changedKeyArr.push('DMS_Smoking');
                }

                return changedKeyArr;
            },
            handleCancelAllSettings() {
                let params = this.apiCompanyInfo;
                this.resetAllSettings(params);
            },
            resetAllSettings(params) {
                //记录原始D700,M810数据，用作提交数据时AI选项是否变动比较
                this.originalD700Data = params && params.DeviceSettings && params.DeviceSettings.D700 || null;
                this.originalD700SData = params && params.DeviceSettings && params.DeviceSettings.D700S || null;
                this.originalM800Data = params && params.DeviceSettings && params.DeviceSettings.M800 || null;
                this.originalM810Data = params && params.DeviceSettings && params.DeviceSettings.M810 || null;
                this.originalM820Data = params && params.DeviceSettings && params.DeviceSettings.M820 || null;
                this.resetGlobalSettings(params);
                this.resetDeviceSettings(params);
            },
            resetGlobalSettings(params) {
                this.AlertLanguage = params && params.AlertLanguage || this.$i18n.locale;
                this.companyId = params && params.Id || '';
                this.unit = params && params.Unit || 'Metric';
                this.dateFormat = params && params.DateFormat || 'YYYY-MM-DD';
                this.AlertRecordTime = params && params.AlertRecordTime !== undefined ? params.AlertRecordTime : 0;
                this.loginMethod = params && params.DriverFacialRecognition !== undefined ? params.DriverFacialRecognition : 0;
                this.scanTTL = params && params.ScanTTL !== undefined ? params.ScanTTL : 5;
                this.takePictureTime = params && params.TakePictureTime !== undefined ? params.TakePictureTime : 20;
                this.muteRecord = params && params.MuteRecord !== undefined ? params.MuteRecord : 0;
                this.callVolume = params && params.CallVolume !== undefined ? params.CallVolume : 2;
                this.callRecord = params && params.CallRecord !== undefined ? params.CallRecord : 0;
                this.alertVolume = params && params.AlertVolume !== undefined ? params.AlertVolume : 2;
                this.GsensorEventSensitivityProxy = 25 - params.Sensitivity;//Parking and Collision Alert Sensitivity数据放到最外层
            },
            resetDeviceSettings(params) {
                if (!params.DeviceSettings) {
                    this.deviceSettings.activeSetting = '';
                    this.deviceSettings.D700.show = false;
                    this.deviceSettings.D700S.show = false;
                    this.deviceSettings.M810.show = false;
                    this.deviceSettings.M820.show = false;
                    return;
                }
                let emptyFirmware = {
                    Show: false,
                    CurrentVersion: {
                        Version: '',
                        ReleaseNote: ''
                    },
                    LatestVersion: {
                        Version: '',
                        ReleaseNote: ''
                    }
                };
                let deviceSettings = params.DeviceSettings;
                if (deviceSettings.D700) {
                    this.deviceSettings.D700.show = true;
                    if (!params.DeviceSettings.D700.FirmwareVersion)
                        params.DeviceSettings.D700.FirmwareVersion = emptyFirmware;
                    if (params.DeviceSettings.D700.FirmwareVersion.Show !== false)
                        params.DeviceSettings.D700.FirmwareVersion.Show = true;
                    this.deviceSettings.D700.data = Object.assign({}, params.DeviceSettings.D700);
                    this.D700FCWLevel = 2 - this.deviceSettings.D700.data.FCWLevel;
                }
                if (deviceSettings.D700S) {
                    this.deviceSettings.D700S.show = true;
                    if (!params.DeviceSettings.D700S.FirmwareVersion)
                        params.DeviceSettings.D700S.FirmwareVersion = emptyFirmware;
                    if (params.DeviceSettings.D700S.FirmwareVersion.Show !== false)
                        params.DeviceSettings.D700S.FirmwareVersion.Show = true;
                    this.deviceSettings.D700S.data = Object.assign({}, params.DeviceSettings.D700S);
                    this.D700SFCWLevel = 2 - this.deviceSettings.D700S.data.FCWLevel;
                }
                if (deviceSettings.M800) {
                    this.deviceSettings.M800.show = true;
                    if (!params.DeviceSettings.M800.FirmwareVersion)
                        params.DeviceSettings.M800.FirmwareVersion = emptyFirmware;
                    if (params.DeviceSettings.M800.FirmwareVersion.Show !== false)
                        params.DeviceSettings.M800.FirmwareVersion.Show = true;
                    this.deviceSettings.M800.data = Object.assign({}, params.DeviceSettings.M800);
                    this.M800FCWLevel = 2 - this.deviceSettings.M800.data.FCWLevel;
                }
                if (deviceSettings.M810) {
                    this.deviceSettings.M810.show = true;
                    if (!params.DeviceSettings.M810.FirmwareVersion)
                        params.DeviceSettings.M810.FirmwareVersion = emptyFirmware;
                    if (params.DeviceSettings.M810.FirmwareVersion.Show !== false)
                        params.DeviceSettings.M810.FirmwareVersion.Show = true;
                    this.deviceSettings.M810.data = Object.assign({}, params.DeviceSettings.M810);
                    this.M810FCWLevel = 2 - this.deviceSettings.M810.data.FCWLevel;
                }
                if (deviceSettings.M820) {
                    this.deviceSettings.M820.show = true;
                    if (!params.DeviceSettings.M820.FirmwareVersion)
                        params.DeviceSettings.M820.FirmwareVersion = emptyFirmware;
                    if (params.DeviceSettings.M820.FirmwareVersion.Show !== false)
                        params.DeviceSettings.M820.FirmwareVersion.Show = true;
                    this.deviceSettings.M820.data = Object.assign({}, params.DeviceSettings.M820);
                    this.M820FCWLevel = 2 - this.deviceSettings.M820.data.FCWLevel;
                }
                if (deviceSettings.D700)
                    this.deviceSettings.activeSetting = D700;
                else if (deviceSettings.D700S)
                    this.deviceSettings.activeSetting = D700S;
                else if (deviceSettings.M800)
                    this.deviceSettings.activeSetting = M800;
                else if (deviceSettings.M810)
                    this.deviceSettings.activeSetting = M810;
                else if (deviceSettings.M820)
                    this.deviceSettings.activeSetting = M820;
                else
                    this.deviceSettings.activeSetting = '';
                this.switchActiveSetting(this.deviceSettings.activeSetting);
            },
            getCompanyInfoFromApi() {
                let promise = (resolve, reject) => {
                    this.$serverApi.company.getCompany(data => {
                        resolve(data);
                    }, (error) => {
                        reject(error);
                    });
                };
                return new Promise(promise);
            },
            updateCompanyInfo(params) {
                let promise = (resolve, reject) => {
                    this.$serverApi.company.updateCompany(params, data => {
                        resolve(data);
                    }, error => {
                        reject(error);
                    });
                };
                return new Promise(promise);
            },
            updateFirmware(params) {
                let promise = (resolve, reject) => {
                    this.$serverApi.company.updateFirmware(params, data => {
                        resolve(data);
                    }, error => {
                        reject(error);
                    });
                };
                return new Promise(promise);
            },
            getFleetFromApi() {
                let promise = (resolve, reject) => {
                    this.$serverApi.fleet.fleetList(data => {
                        resolve(data);
                    }, error => {
                        reject(error);
                    })
                };
                return new Promise(promise);
            },
            startLoading() {
                this.closeLoading();
                this.loadingMask = this.$loading({
                    lock: true,
                    text: this.$t('globalText.loading'),
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0)'
                });
            },
            closeLoading() {
                if (this.loadingMask && this.loadingMask.close)
                    this.loadingMask.close();
            },
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $border-color = $color_gray_third
    $font-color = $color_black_first
    $font-grey-color = $color_black_third
    $font-hover-color = $color_blue_second

    .sl-row
        display flex
        justify-content space-between
        padding-bottom 20px

        .sl-left
            width calc(50% - 10px)

    .sl-top
        margin-bottom 20px

    @media screen and (max-width: 1700px) {
        .page-settings .ps-label-option .ps-ldw-audio-alert,
        .page-settings .ps-label-option .ps-fcw-audio-alert,
        .page-settings .ps-label-option .ps-dms-option {
            width 200px !important
        }
    }

    @media only screen and (max-width: 1600px) {
        .page-settings .ps-mobile360-d700 .ps-name {
            /*padding-right 80px !important*/
            width 131px !important
        }

        .page-settings .ps-label-option .ps-ldw-audio-alert,
        .page-settings .ps-label-option .ps-dms-option {
            width 191px !important
        }

        .page-settings .ps-label-option .ps-fcw-audio-alert {
            width 140px !important
        }

        .page-settings .ps-mobile360-d700 .ps-ai-left-box {
            width calc(55% - 2px) !important
        }

        .page-settings .ps-mobile360-d700 .ps-ai-right-box {
            width 45% !important
        }

        .page-settings .ps-mobile360-d700 .ps-bottom .padding-left-space,
        .page-settings .ps-mobile360-d700 .ps-top .padding-left-space {
            padding-left 10px !important
        }

        .page-settings .ps-mobile360-d700 .ps-top .ps-fcw-padding .ps-fcw-slider {
            width 170px !important
        }

        .page-settings .ps-fcw-row .ps-slider-width {
            width 140px !important
        }

        .page-settings .ps-select-width, .page-settings .ps-input-width {
            width 194px !important
        }
    }

    .page-settings
        padding 20px 40px 40px
        cursor default

        .ps-select-width, .ps-slider-width, .ps-input-width
            width 259px

        .no-select-text
            -moz-user-select: none; /*火狐*/
            -webkit-user-select: none; /*webkit浏览器*/
            -ms-user-select: none; /*IE10*/
            -khtml-user-select: none; /*早期浏览器*/
            user-select: none;

        .ps-btn
            font-size 14px
            line-height 14px
            width 140px
            margin-right 20px

        .ps-btn-cancel
            background-color $color_black_third
            color #ffffff

        .ps-driver-login-settings
            padding-bottom 5px

            .ps-global-setting-row
                padding-bottom 15px

                >>> .el-radio:hover
                    color $font-hover-color

        .ps-audio-settings
            height 190px
            .ps-global-setting-row
                padding-bottom 10px

        .ps-action-btns
            padding-left 25px

        .ps-global-setting-row
            display flex
            padding-top 15px
            padding-bottom 5px
            line-height 30px

            .ps-left
                width 44%
                -moz-user-select: none; /*火狐*/
                -webkit-user-select: none; /*webkit浏览器*/
                -ms-user-select: none; /*IE10*/
                -khtml-user-select: none; /*早期浏览器*/
                user-select: none;

            .ps-right
                .ps-box
                    min-width 194px

                .ps-min-max >>> .el-slider__marks-text
                    width 28px

        .ps-unit-settings .ps-audio-settings .ps-global-setting-row:last-child
            padding-bottom 25px

        .ps-tabs-box
            -moz-user-select: none; /*火狐*/
            -webkit-user-select: none; /*webkit浏览器*/
            -ms-user-select: none; /*IE10*/
            -khtml-user-select: none; /*早期浏览器*/
            user-select: none;

            .ps-icon
                padding-right 5px

            .ps-tabs
                cursor default
                color #ffffff
                display inline-block
                margin-right 5px
                padding 8px 40px
                background-color $color_black_third

            .ps-tabs:hover
                cursor pointer
                background-color #a6a6a6

            .ps-tab-active
                background-color $color_blue_second

            .ps-tab-active:hover
                cursor default
                background-color $color_blue_second

        .ps-mobile360-d700
            padding 25px 0 10px
            -moz-user-select: none; /*火狐*/
            -webkit-user-select: none; /*webkit浏览器*/
            -ms-user-select: none; /*IE10*/
            -khtml-user-select: none; /*早期浏览器*/
            user-select: none;

            .ps-ai-left-box, .ps-ai-right-box
                width 50%
                float left
                .left-border
                    border-left 1px solid $border-color

            .ps-ai-left-box
                width calc(50% - 2px)
                border-right 1px solid $border-color

            .ps-label-option
                cursor pointer

                .ps-ldw-audio-alert, .ps-fcw-audio-alert, .ps-dms-option
                    display inline-block
                    width 245px

            .ps-label-option:hover
                color $font-hover-color

            .ps-top, .ps-bottom
                .ps-name, .ps-switch
                    display inline-block

                .ps-checkbox
                    cursor pointer
                    margin-left 5px
                    position relative
                    top 2px

                .padding-left-space
                    padding-left 20px

                .padding-left-level3-space
                    padding-left 40px

            .ps-name
                line-height 14px
                width 141px

            .ps-name-level2
                display inline-block
                line-height 14px
                width 60%

            /*padding-right 90px*/

            .ps-top

                justify-content space-between
                line-height 20px
                padding-bottom 20px

                .ps-ldw
                    height 80px

                .ps-fcw-row, .ps-ldw-row
                    padding-bottom 25px

                .ps-fcw-padding
                    padding-left 15px

                    .ps-fcw-slider
                        position relative
                        width 180px

                        .ps-mask
                            z-index 1500
                            position absolute
                            left 0
                            right -30px
                            top 0
                            bottom -5px

            .ps-bottom, .ps-top
                .ps-dms-row
                    height 40px

                .ps-dms-option-row
                    height 35px

                .ps-dms-option-row-D710
                    height 40px

                .ps-dms-option-row-level3
                    height 25px

                    .ps-dms-option
                        display inline-block
                        line-height 20px

            .item-padding
                padding-bottom 25px

            .ps-version
                padding-left 15px
                padding-right 15px
                padding-bottom 15px
                line-height 14px

            .ps-upgrade
                padding-left 15px
                padding-right 15px
                padding-top 20px
                display flex
                justify-content space-between

                .ps-icon
                    padding-right 5px
                    position relative
                    top 1px

                .ps-msg
                    .ps-new-version
                        color red

                    .ps-row
                        height 37px

            .ps-version-detail-d700, .ps-version-detail-d710
                margin-left 15px
                margin-right 15px
                border 1px solid $border-color
                overflow-y auto
                height 130px
                padding 10px
                margin-bottom 20px
                line-height 20px
            .ps-version-detail-m810
                margin-left 15px
                margin-right 15px
                border 1px solid $border-color
                overflow-y auto
                height 80px
                padding 10px
                margin-bottom 20px
                line-height 20px

            .ps-version-detail-no-upgrade
                height 220px

            .ps-version-detail-no-upgrade-m810
                height 220px

        .ps-mobile360-m810-height
            height 280px

        .ps-mobile360-d700-height, .ps-mobile360-d710-height
            height 280px

    .checkbox-grey
        color $font-grey-color

        >>> .el-checkbox
            color $font-grey-color

        >>> .el-checkbox__inner
            border 1px solid $font-grey-color

        >>> .el-checkbox__inner::after
            border-color $font-grey-color

        >>> .el-checkbox__input.is-checked .el-checkbox__inner
            border-color $font-grey-color

    .slider-grey
        color $border-color

    >>> .el-checkbox__input.is-checked .el-checkbox__inner
        border-color $font-color
        background-color #ffffff

    >>> .el-checkbox__inner::after
        border-color $font-color

    >>> .el-checkbox__inner
        border-color $font-color

    >>> .el-checkbox__input.is-focus .el-checkbox__inner
        border-color $font-color

    >>> .el-radio__input.is-checked .el-radio__inner
        border-color $font-color
        background-color #ffffff

    >>> .el-radio__inner::after
        background-color $font-color
        width 8px
        height 8px

    >>> .el-radio__input.is-checked + .el-radio__label
        color $font-color

    >>> .el-slider__stop
        top: -8px;
        height: 8px;
        width: 2px;
        border-radius: 5px;
        background-color: $border-color;

    >>> .el-slider__bar
        border-top-left-radius 0

    >>> .el-slider__runway
        background-color $border-color;
        border-top-right-radius 0
        border-top-left-radius 0

        .el-slider__marks-text:first-of-type
            transform translateX(0%) !important

        .el-slider__marks-text:last-of-type
            text-align right
            transform translateX(-100%) !important

    >>> .el-slider__button
        background-color $color_blue_second

    >>> .el-slider__runway.disabled .el-slider__bar
        background-color $border-color

    >>> .el-slider__runway.disabled .el-slider__button
        border-color $border-color
        background-color $border-color

    >>> .el-switch__core
        border-color $border-color !important
        background-color $border-color !important

    >>> .el-switch.is-checked .el-switch__core
        border-color $color_blue_second !important
        background-color $color_blue_second !important

    .ps-unit-settings,.ps-alert-settings
        padding-bottom 15px

    .ps-unit-settings,.ps-alert-settings,.ps-audio-settings
        >>> .el-icon-arrow-up:before
            content "\e78f"

        >>> .el-input__suffix
            border-left 1px solid $border-color

    .ps-alert-settings
        >>> .el-input__inner
            text-align center
    .ps-alert-settings
        padding-bottom 15px

    .ps-slider >>> .el-slider__marks-text
        font-size 12px
        margin-top 10px

    .slide_top >>> .el-slider__marks-text
        font-size 12px
        margin-top 15px
</style>
