<template>
    <div class="fleet-tree">
        <div class="tree-root">
            <div class="tree-item item-father">
                <span :class="{'tree-open': open, 'tree-close': !open}" @click="open = !open"/>
                <el-checkbox class="ft-pub-checkbox"
                             v-model="checkAll"
                             :indeterminate="isIndeterminate"
                             @change="handleCheckAllChange">{{rootFleet.name}}
                </el-checkbox>
            </div>
            <div v-show="open">
                <div class="tree-item" v-for="(fleet, index) in fleetList">
                    <el-checkbox class="ft-pub-checkbox"
                                 v-model="fleetsCheckStatus[index]"
                                 @change="handleFleetChange">
                        <i class="ft-icon via-vehicles" :style="{color: fleet.color}"/>{{fleet.name}}
                    </el-checkbox>
                </div>
            </div>
            <!--            多级树的demo-->
            <!--            <div class="tree-cate"><span class="tree-close"/>tree-item1</div>-->
            <!--            <div class="tree-group">-->
            <!--                <div class="tree-item"><span class="tree-open"/>tree-item1</div>-->
            <!--                <div class="tree-item">00lksjld</div>-->
            <!--                <div class="tree-group">-->
            <!--                    <div class="tree-item">tree-item1</div>-->
            <!--                    <div class="tree-item">tree-item1</div>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "FleetTree",
        props: {
            rootFleet: {type: Object, required: true},
            fleetList: {type: Array, required: true}
        },
        beforeMount() {
            this.resetFleetsCheckStatus();
        },
        data() {
            return {
                open: true,
                checkAll: true,
                isIndeterminate: false,
                fleetsCheckStatus: [],
            }
        },
        computed: {
            checkedFleetIds() {
                let ids = [];
                this.fleetList.forEach((fleet, index) => {
                    if (this.fleetsCheckStatus[index])
                        ids.push(fleet.fleetId);
                });
                return ids;
            }
        },
        watch: {
            fleetList() {
                this.resetFleetsCheckStatus();
            }
        },
        methods: {
            resetFleetsCheckStatus() {
                let status = [];
                let isAllChecked = true;
                this.fleetList.forEach(fleet => {
                    status.push(!!fleet.checked);
                    isAllChecked = isAllChecked && fleet.checked
                });
                this.fleetsCheckStatus = status;
                this.checkAll = isAllChecked;
            },
            handleCheckAllChange(status) {
                this.isIndeterminate = false;
                this.checkAll = status;
                this.fleetsCheckStatus = this.fleetsCheckStatus.map(item => item = status);
            },
            handleFleetChange() {
                let checkedFleet = this.fleetsCheckStatus.filter(status => status);
                this.checkAll = checkedFleet.length === this.fleetsCheckStatus.length;
                this.isIndeterminate = checkedFleet.length < this.fleetsCheckStatus.length && checkedFleet.length > 0;
            },
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $border-color = $color_gray_third
    $font-color = $color_black_second

    .fleet-tree
    .tree-root
        overflow hidden

        .ft-pub-checkbox
            >>> .el-checkbox__input.is-checked + .el-checkbox__label
                color $font-color
            >>> .el-checkbox__input.is-checked .el-checkbox__inner,
            >>> .el-checkbox__input.is-indeterminate .el-checkbox__inner
                background-color #ffffff
                border-color $font-color
            >>> .el-checkbox__inner::after
                border-color $font-color
                border-width 2px
            >>> .el-checkbox__input.is-indeterminate .el-checkbox__inner::before
                background-color $font-color
                height 3px
                top 5px
                transform scale(.7)
            >>> .el-checkbox__inner
                border-color $font-color

        .tree-open, .tree-close
            cursor pointer
            background-color #ffffff
            z-index 1000

        .tree-open:before
            background url("../../assets/image/tree-unfold.svg") no-repeat

        .tree-close:before
            background url("../../assets/image/tree-fold.svg") no-repeat

        .tree-open:before, .tree-close:before
            background-color #ffffff
            position absolute
            top 6px
            left 2px
            content ''
            width 25px
            height 30px
            font-size 14px
            background-size 18px
            display inline-block

        .tree-group
            overflow hidden
            position relative
            padding-left 25px

        .tree-item, .tree-cate
            cursor default
            height 30px
            line-height 30px
            position relative
            padding-left 30px

        .tree-item:before, .tree-group:before,
        .tree-cate:before, .tree-cate:before
            content ''
            position absolute
            top -15px
            left 10px
            border-left 2px dotted $border-color
            width 0
            height 100%

        .tree-item:after, tree-cate:after
            content ''
            position absolute
            left 10px
            top 15px
            border-bottom 2px dotted $border-color
            width 15px

        .item-father:before, .item-father:after
            border-width 0

        .tree-item
            .ft-icon
                padding-right 5px
                font-size 18px
                position relative
                top 3px

        .tree-group:last-child:before
            height 0
</style>
