<template>
    <div class="apply-settings">
        <base-pop-dialog width="480px" @onClose="closeApplySettingWindow">
            <template v-slot:title>{{$t('settings.applySettings')}}</template>
            <template v-slot:content v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.2)">
                <div class="apply-settings-content">
                    <div class="as-tips">{{$t('settings.selectWhichFleetsToApplyTheChangesTo')}}</div>
                    <div class="as-fleets">
                        <fleet-tree ref="fleetTree" :root-fleet="rootFleet" :fleet-list="fleetList"/>
                    </div>
                    <div class="as-select-checkbox">
                        <el-checkbox class="as-checkbox" v-model="coverAllFleet">
                            {{$t('settings.selectToOverrideAllSettingsForTheChosenFleets')}}
                        </el-checkbox>
                    </div>
                    <div class="as-btns-box">
                        <el-button class="as-btn" type="primary" size="small" @click="openConfirmWindow">
                            {{$t('globalText.apply')}}
                        </el-button>
                        <el-button class="as-btn" type="info" size="small" @click="closeApplySettingWindow">
                            {{$t('globalText.cancel')}}
                        </el-button>
                    </div>
                </div>
            </template>
        </base-pop-dialog>
        <base-pop-dialog v-if="isOpenConfirmWindow" width="560px" @onClose="closeConfirmWindow">
            <template v-slot:title>{{$t('settings.applySettings')}}</template>
            <template v-slot:content v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.2)">
                <div class="apply-settings-confirm">
                    <div class="asc-tips">{{$t('settings.pressOKToApplyTheChanges')}}</div>
                    <div class="asc-btns-box">
                        <el-button size="small" type="primary" class="asc-btn" @click="handleApplySettingsAction">
                            {{$t('globalText.oK')}}
                        </el-button>
                        <el-button size="small" type="info" class="asc-btn asc-cancel" @click="closeConfirmWindow">
                            {{$t('globalText.cancel')}}
                        </el-button>
                    </div>
                </div>
            </template>
        </base-pop-dialog>
    </div>
</template>

<script>
    import BasePopDialog from "../common/BasePopDialog";
    import FleetTree from "../../components/settings/FleetTree";

    export default {
        name: "ApplySettings",
        props: {
            rootFleet: {type: Object, required: true},
            fleetList: {type: Array, required: true}
        },
        components: {
            BasePopDialog,
            FleetTree
        },
        data() {
            return {
                coverAllFleet: true,
                loading: true,
                isOpenConfirmWindow: false,
            }
        },
        methods: {
            closeApplySettingWindow() {
                this.$emit('close');
            },
            closeConfirmWindow() {
                this.isOpenConfirmWindow = false;
            },
            openConfirmWindow() {
                this.isOpenConfirmWindow = true;
            },
            handleApplySettingsAction() {
                let checkedIds = this.$refs.fleetTree.checkedFleetIds;
                if (checkedIds.length)
                    this.$emit('updateSettings', {
                        fleetIds: checkedIds,
                        coverAllFleet: this.coverAllFleet
                    });
                this.isOpenConfirmWindow = false;
                this.$emit('close');
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $font-color = $color_black_second

    .apply-settings
        display block

    .apply-settings-content
        -moz-user-select: none; /*火狐*/
        -webkit-user-select: none; /*webkit浏览器*/
        -ms-user-select: none; /*IE10*/
        -khtml-user-select: none; /*早期浏览器*/
        user-select: none;
        padding 26px 20px

        .as-tips
            height 30px

        .as-fleets
            min-height 200px

        .as-select-checkbox
            height 50px

            .as-checkbox
                >>> .el-checkbox__input.is-checked + .el-checkbox__label
                    color $font-color

                >>> .el-checkbox__input.is-checked .el-checkbox__inner,
                >>> .el-checkbox__input.is-indeterminate .el-checkbox__inner
                    background-color #ffffff
                    border-color $font-color

                >>> .el-checkbox__inner::after
                    border-color $font-color
                    border-width 2px

                >>> .el-checkbox__input.is-indeterminate .el-checkbox__inner::before
                    background-color $font-color
                    height 3px
                    top 5px
                    transform scale(.7)

                >>> .el-checkbox__inner
                    border-color $font-color

        .as-btns-box
            text-align center

            .as-btn
                width 140px
                margin-left 15px
                margin-right 15px

    .apply-settings-confirm
        .asc-tips
            line-height 129px
            font-size 18px
            text-align center

        .asc-btns-box
            text-align center
            padding-bottom 24px

            .asc-btn
                width 140px
                margin-left 15px
                margin-right 15px
</style>