<template>
    <div class="setting-layout">
        <div class="sl-header">{{$t('settings.globalSettings')}}</div>
        <div class="sl-row sl-top">
            <div class="sl-left">
                <block-box :header="$t('settings.GeneralSettings')">
                    <slot name="unitSettings"/>
                </block-box>
            </div>
            <div class="sl-right">
                <block-box :header="$t('settings.RecordSettings')">
                    <slot name="alertSettings"/>
                </block-box>
            </div>
        </div>
        <div class="sl-row sl-middle">
            <div class="sl-left">
                <block-box :header="$t('settings.driverLoginSettings')" class="sl-driver-login-settings">
                    <slot name="driverLoginSettings"/>
                </block-box>
            </div>
            <div class="sl-right">
                <block-box :header="$t('settings.alertSettings')" class="sl-audio-settings">
                    <slot name="audioSettings"/>
                </block-box>
            </div>
        </div>
        <div class="sl-top-action-btn">
            <slot name="topBtn"/>
        </div>
    </div>
</template>

<script>
    import BlockBox from "./BlockBox";

    export default {
        name: "GlobalSettingsLayout",
        components: {
            BlockBox,
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $border-color=$color_gray_third

    .sl-header
        font-size 16px
        line-height 16px
        font-weight 600
        padding-bottom 15px

    .sl-row
        display flex
        justify-content space-between
        padding-bottom 20px

        .sl-left, .sl-right
            width calc(50% - 10px)

    .sl-top
        margin-bottom 20px



    .sl-bottom
        margin-bottom 40px

    .sl-top-action-btn, .sl-bottom-action-btn
        padding-left 25px

    .sl-top-action-btn
        padding-bottom 25px
        margin-bottom_bak 20px
        border-bottom_bak 1px solid $border-color
</style>
