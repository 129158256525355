<template>
    <div class="setting-block-box">
        <div class="sbb-header">{{header}}</div>
        <div class="sbb-main">
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BlockBox",
        props: {
            header: {required: true, type: String},
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $border-color=$color_gray_third

    .setting-block-box
        border 1px solid $border-color
        background-color $color_gray_first
        padding 15px 25px

        .sbb-header
            font-size 14px
            line-height 14px
            font-weight 600
            padding-bottom 15px

        .sbb-main
            border-radius 10px
            padding 0 10px
            border 1px solid $border-color
            background-color #ffffff
</style>
