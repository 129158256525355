<template>
    <span class="setting-lock" style="display: none;">
        <label class="sl-label">
            <input class="sl-input" type="checkbox" v-bind:checked="isLocked"
                   v-on:change="$emit('change', $event.target.checked)"/>
            <span v-if="isLocked" class="sl-icon via-lock"/>
            <span v-else class="sl-icon via-unlock"/>
        </label>
    </span>
</template>

<script>
    export default {
        name: "SettingLock",
        model: {
            prop: 'isLocked',
            event: 'change'
        },
        props: {
            isLocked: {type: Boolean, required: true}
        },
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .setting-lock
        overflow hidden
        padding-right 5px
        cursor pointer

        .sl-label
            display inline-block
            width 11px

        .sl-input
            display none

        .sl-icon
            cursor pointer

        .sl-label:hover .sl-icon::before
            color $color_blue_second !important
</style>