<template>
    <base-pop-dialog width="560px" @onClose="handleClose">
        <template v-slot:title>{{$t('settings.upgradeFirmware')}}</template>
        <template v-slot:content v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.2)">
            <div class="upgrade-firmware">
                <div class="uf-tips">
                    <div class="uf-row">{{$t('settings.pressOKToUpgradeYourMobile360D700Devices', {device: deviceName})}}</div>
                    <div class="uf-row">{{$t('settings.firmwareVersion')}}: {{version}}</div>
                </div>
                <div class="ut-btns-box">
                    <el-button :loading="isLoading" type="primary" size="small" class="ut-btn" @click="handleUpgradeFirmware">{{$t('globalText.oK')}}</el-button>
                    <el-button :disabled="isLoading" type="info" size="small" class="ut-btn" @click="handleClose">{{$t('globalText.cancel')}}</el-button>
                </div>
            </div>
        </template>
    </base-pop-dialog>
</template>

<script>
    import BasePopDialog from "../common/BasePopDialog";

    export default {
        name: "UpgradeFirmware",
        props: {
            deviceName: {type: String, required: true},
            version: {type: String, required: true},
            mainModel: {type: String, required: true},
            closeLoading: {type: Date, required: true},
        },
        components: {
            BasePopDialog
        },
        data() {
            return {
                isLoading: false,
            }
        },
        watch: {
            closeLoading() {
                this.isLoading = false;
            }
        },
        methods: {
            handleClose() {
                this.$emit('close');
            },
            handleUpgradeFirmware() {
                this.isLoading = true;
                this.$emit('upgrade', {mainModel: this.mainModel, version: this.version});
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .upgrade-firmware
        word-break normal
        .uf-tips
            text-align center
            padding-top 34px
            padding-bottom 38px
            font-size 16px

            .uf-row
                line-height 32px

        .ut-btns-box
            padding-bottom 24px
            text-align center

            .ut-btn
                width 140px
                margin-left 15px
                margin-right 15px
</style>